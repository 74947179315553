import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Yebra from "../stylesheets/assets/yebra.jpg"

// import { rhythm } from '../utils/tipography';

const AboutPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="about hero full" style={{}}>
      {/*Background image*/}
      <div
        className="background-image hero  profile full"
        style={{ backgroundImage: "url(" + Yebra + ")" }}
      >
        <img alt="" className src={Yebra} style={{ display: "none" }} />
      </div>
      {/*End background image*/}
      {/*Container*/}
      <div className="container vertical-align" style={{}}>
        <div className="six columns  profile-info">
          <div className="content clearfix">
            <div className="content-inner">
              <h1>
                Carlos Yebra<span>Fotógrafo Profesional</span>
              </h1>

              <p>
                Este fotógrafo nacido en Lleida en 1951, aterrizó hace mucho por
                Zahara, de veraneo, pero no fue hasta sufrir un accidente
                cardiovascular que se asentó definitivamente en el pueblo. “Me
                enamoré de la zona, me atrapó y me he acabado quedando aquí
                después de que, el 27 de enero de 2006, un aneurisma de aorta
                hiciera que Carlos Yebra dejara de ser fotógrafo de publicidad y
                empezara a dedicarse a la fotografía de autor”.
              </p>
              <p>
                Zahara como remedio al stress de Madrid. “Esto es un lujo, pero
                no un privilegio, porque no es una cuestión de dinero, sino de
                estar aquí para poder disfrutarlo”, dice delante de un morrillo
                de atún. “Aquí es donde descubro ‘mi’ sur, el Sur, que no es una
                cuestión geográfica, sino subjetiva”, advierte. “Aquí el paso
                del tiempo es diferente, aquí me da tiempo a hacer la obra que
                siempre había querido, pero nunca hacía. Es Dios que decide
                mandarme aquí en vez de al otro barrio”.
              </p>
              {/* <p>Y también está la gente de Zahara. “Aquí voy cumpliendo otras metas. Me he hecho patrón de yate, me he dedicado a la fotografía creativa, monto a caballo,… Para mí el sur es más un estado de ánimo que un lugar geográfico. El sur aparece cuando decides dejar de tener un norte”.</p>
                <p>En sus fotografías de la almadraba y de los almadraberos, de los atunes, de la playa y del pueblo; en las fotografías que pueblan estas páginas, se perciben todavía mejor que en sus palabras las razones de por qué Carlos Yebra escogió Zahara. “Es difícil de explicar. Es importante cómo son recibidos los forasteros aquí, porque tienes la sensación de que es también el sitio el que te acoge y te recibe. Porque igual te da un golpe en la cara con su Levante, como enfría las noches de verano con su Poniente. Cuando estás aquí ya nunca más te sientes como un extraño, es como si hubieras estado siempre aquí”. A pesar de que jura que dejó atrás la presión del trabajo diario y los compromisos, Carlos Yebra tiene su agenda llena de proyectos. “Además de ir descubriendo más cosas al sur del sur, quiero trasladar todo lo que sé de fotografía y de arte aquí, quiero crear una sala de arte relacionada con la fotografía y seguir fotografiando el sur, a ver si por fin lo encuentro. Creo que acabaré por conseguirlo, pero seguro que tardaré mucho, porque aquí no existe el tiempo”, asegura con un brillo fugaz en los ojos.</p>
                <p>En Zahara sólo existen dos estaciones, “el verano y el invierno, y se nota porque el sol está más alto o más bajo: tengo seis meses para hacer mi obra (invierno) y otros seis para trabajarla y descansar, con una mención especial a los 365 atardeceres únicos e irrepetibles que tiene Zahara”, concluye Carlos Yebra, empeñado en fotografiar el alma de este pueblo, una tarea que no es, desde luego, tan sencilla como podría parecérselo al viajero que pisa por primera vez esta playa deslumbrante.</p> */}
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat. Duis aute irure dolor
                  in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> */}
              <Link to="/blog" className="link link-light">
                Descubre mi mundo
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*End container*/}
      {/*Footer*/}
      <footer className="footer-transparent footer dark">
        {/*Container*/}
        <div className="container">
          <div className="row">
            <div className="eight columns rights">
              <p>
                Copyrights © 2020 - Carlos Yebra Fotógrafo
                <br />
                <a href="mailto:ghribbenaissa@gmail.com">
                  carlosyebra@hotmail.es
                </a>
              </p>
            </div>
            <div className="eight columns tRight">
              <ul className="socials-footer dark socials">
                <li>
                  <a href="#">Facebook</a>
                </li>
                <li>
                  <a href="#">Pinterest</a>
                </li>
                <li>
                  <a href="#">Instagram</a>
                </li>
                <li>
                  <a href="#">Twitter</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*End container*/}
      </footer>
      {/*End footer*/}
    </section>
  </Layout>
)

export default AboutPage
